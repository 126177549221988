<template>
  <div>
    <Header/> 
    <div class="contents_text_ones">
      <!-- <router-link :to="{path:'/'}">
        <img class="contents_img_1_ones" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="contents_img_2_ones" src="../../assets/index/add_icon.png">
        <div calss="contents_study_ones">{{title}}</div>
    </div>
    
    <div class="sv_1_1s" v-loading="loading">
        <div class="menu-con">
            <div class="arrow-btn">
                <button class="clear-btn clear-button" @click="last">
                    <img src="../../assets/share_pic_sort/left_arrow_btn.png">
                </button>
            </div>
            <div class="date-text">{{now}} / {{count}}</div>
            <div class="arrow-btn">
                <button class="clear-btn clear-button" @click="next">
                    <img src="../../assets/share_pic_sort/right_arrow_btn.png">
                </button>
            </div>
            <div class="share-btn-con">
                <button class="saves" @click="downhander()">保存</button>
            </div>
        </div>
        <div id="imageDom">
            <img :src="src" id="imgs" style='width:100%;height:100%;'>
        </div>
        <div style="width:100%;height:90px;"></div>
    </div>

  </div>
</template>

<style>

.anav{
    position: relative;
    top: -25vh;
}
.canvas p{
    margin:0;
    font-weight: 500;
    color:#fff;
}
.saves{
    background: #a6865d;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    width: 15vw;
    height: 4vh;
}
.savess{
    background: #a6865d;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
    width: 25vw;
    height: 4vh;
}
.share-btn-con{
    margin-left:auto;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    color:#fff;
    margin-right: 3vw;
}
.date-text{
   padding-left: 10px;
   padding-right: 10px; 
   color:#fff;
}
.clear-button img{
    margin: 0px;
    padding: 0px;
    width: 40px;
    height: 40px;
}
.clear-btn{
    display: inline;
    line-height: 0;
    padding: 0;
    background: transparent;
    border: none;  
}
.clear-button{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    color: #000;
    cursor: pointer;
}
.arrow-btn{
    padding: 0;
    width: 40px;
    height: 40px;
}
.menu-con{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    height: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
  .sv_1_1s{
    height: auto;
    width: 100%;
    background-color: rgb(196, 155, 108) !important;
    min-height: 84vh;
  }
  .contents_study_ones{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_text_ones{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_img_1_ones{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .contents_img_2_ones{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
</style>
<script>
import html2canvas from "html2canvas"
import Header from "../Header";
import {apiShares} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        title:'',
        loading:false,
        newest:[
        ],
        financialWisdom:[
        ],
        insuranceConcept:[
        ],
        rootFor:[
        ],
        count:0,
        now:0,
        key:0,
        src:'',
        content:[],
        share_type_id:0,
        status:false,
        testsrn:'',
        user_avatar:'',
        time:"",
        as_height:0,
        // 二維碼地址
        qr_code:require('@/assets/qr_code/life_qr_code.png'),
    }
  },
  created(){
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth()+1;
    let dd = new Date().getDate();
    this.time = yy+'-'+mm+'-'+dd;
    this.user_avatar = this.$store.state.user_avatar;
    let share_type_id = this.$route.query.share_type_id;
    this.share_type_id = share_type_id;
    if(share_type_id && share_type_id > 0){
        apiShares({
            share_type_id:share_type_id,
            lang_id:this.$store.state.lang_id,
        }).then(res=>{
            this.content = res.data.data;
            let id = this.$route.params.id;
            if(res.data.data.length > 0){
                this.count = res.data.data.length;
                for(let i=0; i<res.data.data.length; i++){
                    if(res.data.data[i].id == id){
                        // key 當前key值用於後面點擊下一個或者上一個進行判斷
                        this.key = i;
                        // 因爲key是從0開始所以+1就能知道當前是第幾個
                        this.now = i + 1;

                        this.src = res.data.data[i].image;
                    }
                }
            }
        }).catch(error=>{
            console.log(error);
        })
    }
    this.title = '今日金句';
  },
  computed:{
  },
  watch:{
  },
  methods:{
    downhander(){
        this.downloadImg(this.src,'share');
    },
    //   保存下載圖片
    downloadImg(imgsrc, name){
        var image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function() {
            var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            var context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            var url = canvas.toDataURL(); //得到图片的base64编码数据
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = name || "photo"; // 设置图片名称
            a.href = url; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgsrc;
    },
      shares(){
        var that=this;
        that.loading = true;
        that.status = true;
        let count = 0;
        const timer = setInterval(() => {
            var targetDom = document.getElementById("as");
            var imageDom = document.getElementById("imageDom");
            document.getElementById("as").style.height=(imageDom.offsetHeight) - 150+'px';
            var opts = {useCORS: true, 
            // 下面解决当页面滚动之后生成图片出现白边问题
            height: targetDom.offsetHeight,
            dpi: window.devicePixelRatio * 2, // 解决图片不清晰问题
            scale: 2
            };
                html2canvas(document.getElementById("imageDom"),opts).then(function (canvas) {
                var imgUri = canvas.toDataURL("image/jpeg",1); // 获取生成的图片的url
                that.testsrn=imgUri;
                that.status = false;
                that.as_height = 100;
            });
            count++;
            //销毁定时器
            if(count == 1){
                clearInterval(timer);
                that.loading = false;
                this.$message.success('生產成功');
            }
            }, 1000);
      },
      next(){
          let next_newest = '';
          next_newest = this.content[this.key + 1];
        //   但下一個有内容時才修改
        // 這麽做是因爲我沒法確定下一個的id是多少 只能通過下標獲取當前數組内容的下一個内容 然後拿到對應的id
          if(next_newest){
              location.href="/share_day/"+next_newest.id+'?share_type_id='+this.share_type_id;
          }
      },
      last(){
          let next_newest = '';
          next_newest = this.content[this.key - 1];
        //   但上一個有内容時才修改
        // 這麽做是因爲我沒法確定上一個的id是多少 只能通過下標獲取當前數組内容的上一個内容 然後拿到對應的id
          if(next_newest){
              location.href="/share_day/"+next_newest.id+'?share_type_id='+this.share_type_id;
          }
      },
  }
}
</script>

